<template>
  <div class="c-tag-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag"
    >
      <section>
        <div>
          <znl-input
            v-model="editItem.attributeName"
            :border="true"
            :clearable="true"
            :height="32"
            placeholder=""
            title="属性:"
            title-width="60px"
            type="text"
            width="400px"
          >
          </znl-input>
        </div>
        <div class="m-margin--top-10">
          <znl-input
            v-model="editItem.isUse"
            :border="true"
            :clearable="true"
            :height="32"
            :select-options="isUseOptions"
            form-type="select"
            placeholder=""
            title="是否启用:"
            title-width="60px"
            width="400px"
          >
          </znl-input>
        </div>
        <div class="m-margin--top-10">
          <znl-input
            v-model="editItem.isReadonly"
            :border="true"
            :clearable="true"
            :height="32"
            :select-options="isReadonlyOptions"
            form-type="select"
            placeholder=""
            title="是否只读:"
            title-width="60px"
            width="400px"
          >
          </znl-input>
        </div>
        <div class="m-margin--top-10">
          <znl-input
            v-model="editItem.Multiple"
            :border="true"
            :clearable="true"
            :height="32"
            :select-options="isSelectOptions"
            form-type="select"
            placeholder=""
            title="是否多选:"
            title-width="60px"
            width="400px"
          >
          </znl-input>
        </div>
      </section>
      <section class="m-margin--top-10">
        <div>
          <el-button class="m-grid-btn" type="primary" @click="addData"
            >新建</el-button
          >
          <el-button class="m-grid-btn" plain type="primary" @click="deleteData"
            >删除</el-button
          >
        </div>

        <div class="m-margin--top-10">
          <grid
            ref="list"
            :columns="columns"
            :datas="editItem.itemSource"
            :selection="true"
            :suppressDragLeaveHidesColumns="true"
            :suppressPaginationPanel="false"
            domLayout="autoHeight"
            headerHeight="35"
            type="edit"
          >
          </grid>
        </div>
      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import { attributeEditColumns } from "./config";
import { isUseOptions, defaultData,isSelectOptions,isReadonlyOptions } from "./config";
import { paramsListMap, editItemMap } from "./dataMap";

export default {
  name: "attributeEditView",
  directives: {
    elDragDialog,
  },
  data() {
    return {
      visible: false,
      title: "新增",
      columns: attributeEditColumns,
      editItem: {
        itemSource: [],
        attributeName: "",
        isUse: null,
        isReadonly: false,
        Multiple: null,
        id: 0,
      },
      isUseOptions: isUseOptions,
      isSelectOptions: isSelectOptions,
      isReadonlyOptions: isReadonlyOptions,
      cancelAttribute: [],
    };
  },
  methods: {
    onOpen(data) {
      console.log(data)
      this.isEdit = data !== null;
      this.title = data !== null ? "编辑" : "新增";
      this.editItem = {
        itemSource: [],
        attributeName: "",
        isUse: null,
        isReadonly: false,
        Multiple: null,
        id: 0,
      }
      if (data === null) {
        Object.assign(this.$data.editItem, this.$options.data().editItem);
      } else {
        Object.keys(this.editItem).forEach((key) => {
          this.editItem[key] = data[key];
        });
      }
      this.visible = true;
    },

    // 插入序号
    initRowIndex(list) {
      if (list.length === 0) {
        return [];
      }
      const newList = [];
      list.forEach((item, index) => {
        item.rowIndex = index + 1;
        newList.push(item);
      });
      return newList;
    },

    addData() {
      let line = this.editItem.itemSource;
      let data = Object.assign({}, defaultData);
      line.push(data);
      this.itemSource = this.initRowIndex(line);
    },

    deleteData() {
      let rows = this.$refs.list.getSelectedRows();
      rows.forEach((item) => {
        this.cancelAttribute.push(item.id);
      });
      let RowIndexS = rows.map((item) => item.rowIndex);
      let lines = this.editItem.itemSource.filter(
        (item) => !RowIndexS.includes(item.rowIndex)
      );
      this.editItem.itemSource = this.initRowIndex(lines);
    },

    onSave() {
      // 处理列表
      let paramsList = [];
      if (this.editItem.itemSource.length !== 0) {
        console.log(this.editItem.itemSource)
        this.editItem.itemSource.forEach((item) => {
          const newItem = this.setDataMap(item, paramsListMap, "save");
          paramsList.push(newItem);
        });
      }
      this.editItem.itemSource = Object.assign([], paramsList);
      let params = this.setDataMap(this.editItem, editItemMap, "save");
      params.CancelAttribute = this.cancelAttribute;
      this.$emit("onEditConfig", params);
      this.visible = false;
    },

    handleDrag() {},
  },
  created() {},
};
</script>
<style lang="scss">
.c-tag-view-dialog {
  .el-dialog {
    min-width: 600px;
  }
}
</style>
