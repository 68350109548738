<!--属性维护-->
<template>
  <div class="m-grid m-grid-page p-attribute">
    <section class="m-grid-box_column m-grid-box_col-6">
      <section class="m-grid-search">
        <el-input v-model="searchData.attributeName" class="m-grid-input" placeholder="搜索属性" clearable></el-input>
        <el-button class='m-grid-btn' type="primary" @click="onSearch()">查询</el-button>
        <el-button class='m-grid-btn' plain type="primary" @click="onAdd" v-can="'config_attribute:add'">新建属性</el-button>
        <el-button class='m-grid-btn' plain type="primary" @click="onSort" v-can="'config_attribute:add'">排序</el-button>
      </section>
      <section class="m-grid-list">
        <grid
          ref="list"
          :columns="columns"
          :datas="itemSource"
          :onPageChanged="onSearch"
          :page-index="pagination.page"
          :page-size="pagination.size"
          :selection="false"
          :total-count="pagination.total"
          headerHeight="35"
          type="base"
          @onCellClicked="onCellClicked">
        </grid>
      </section>
    </section>

    <section class="m-grid-box_column m-grid-box_col-4 m-grid_margin-top--40">
      <section class="m-grid-title">
        <p>
          <b>值列表</b>
        </p>
      </section>
      <section class="m-grid-list">
        <grid
          ref="attributeValueList"
          :columns="attributeValueColumns"
          :datas="valueList"
          :selection="false"
          :suppressDragLeaveHidesColumns="true"
          :suppressPaginationPanel="false"
          headerHeight="35"
          type="base">
        </grid>
      </section>
    </section>

    <attribute-edit-view ref="editView" @onEditConfig="onEditConfig"></attribute-edit-view>

    <el-dialog
        v-el-drag-dialog
        :close-on-click-modal="false"
        title="属性排序"
        :visible.sync="sortVisible"
    >
      <DictSort code="Attribute" />
    </el-dialog>
  </div>
</template>

<script>
import {attributeColumns, attributeValueColumns} from "./modules/attribute/config";
import AttributeEditView from "./modules/attribute/AttributeEditView";
import {dateNameArr, editListMap, listDataMap, searchDataMap} from "./modules/attribute/dataMap";
import {getAttributeList, getAttributeValueList, saveAttribute, editAttribute, deleteAttribute} from "@/api/config";
import DictSort from "@/views/config/modules/dict/DictSort";

export default {
  name: "configAttribute",
  components: {
    AttributeEditView,
    DictSort
  },
  data() {
    return {
      columns: attributeColumns,
      attributeValueColumns: attributeValueColumns,
      itemSource: [],
      valueList: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0
      },
      searchData: {
        attributeName: ''
      },
      selectItemId: null,
      isEdit: false,
      selectItem: null,
      sortVisible: false
    }
  },
  methods: {
    async onInit() {
      await this.onSearch()
      const {itemSource} = this
      if (itemSource.length !== 0) {
        const firstItem = itemSource[0]
        await this.onSelect(firstItem.id)
        this.selectItemId = firstItem.id
      }
    },

    async onSearch(page = 1) {
      const {pagination, searchData} = this
      let sendData = {
        Page: page,
        Limit: pagination.size
      }

      Object.entries(searchDataMap).forEach(([key, value]) => {
        sendData[value] = searchData[key]
      })

      const data = await getAttributeList(sendData) // 请求接口

      pagination.page = data.Page
      pagination.total = data.Total
      let items = data.Items

      let newItem = []
      if (items.length) {
        newItem = this.getListData(items, listDataMap)
        newItem.forEach((item, index) => {
          item.rowIndex = (index + 1)
          dateNameArr.forEach(key => {
            item[key] = this.formatDate(item[key], 'yyyy-MM-dd hh:mm')
          })
        })
      }

      this.itemSource = newItem

      // console.log(this.selectItemId)
      // if(this.selectItemId) {
      //   await this.onSelect(this.selectItemId)
      // }

    },


    // 选中
    onCellClicked(row) {
      const {data} = row
      this.onSelect(data.id)
    },

    async onSelect(selectItemId) {
      await this.setSelected(selectItemId)
      this.valueList = await this.onValueList(selectItemId)
    },
    setSelected(selectItemId) {
      this.$refs.list.forEachNode(node => {
        const {data} = node;
        if (data.id === selectItemId) {
          return node.setSelected(true);
        } else {
          return node.setSelected(false);
        }
      });
    },

    async onValueList(selectItemId) {
      const params = {
        DictItemID: selectItemId
      }
      const data = await getAttributeValueList(params)
      let items = data.Linesdic

      let newItem = []
      if (items.length) {
        newItem = this.getListData(items, editListMap)
        newItem.forEach((item, index) => {
          dateNameArr.forEach(key => {
            item[key] = this.formatDate(item[key], 'yyyy-MM-dd hh:mm')
          })
          item.rowIndex = (index + 1)
        })
      }
      return newItem

    },

    onAdd() {
      this.isEdit = false
      this.$refs.editView.onOpen(null)
    },
    onSort() {
      const items = this.itemSource || []
      if (items.length <= 1) {
        this.$message.warning('大于1条才能排序')
        return
      }
      this.sortVisible = true
    },
    async onEditConfig(data) {
      let {isEdit} = this
      let message = ''
      let selectId = 0
      if (isEdit) {
        selectId = await editAttribute(data)
        selectId = data.DictItemID
        message = '编辑成功'
      } else {
        selectId = await saveAttribute(data)
        message = '新增成功'
      }
      this.$message.success(message)
      await this.onSearch(this.pagination.page)
      await this.onSelect(selectId)
    },

    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OriginColumn = attributeColumns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$refs.list.changeColumns(attributeColumns);
    },

    setButtons(params) {
      let data = params.data;
      let buttons = []
      let self = this
      const editBtn = {
        text: '编辑',
        click(params) {
          self.onOpenEdit(params.data)
        }
      }
      const deleteBtn = {
        text: '删除',
        click(params) {
          self.onOpenDelete(params.data)
        }
      }

      if(this.can('config_attribute:edit')) {
        buttons.push(editBtn)

      }

      if (!data.isSystem) {
        buttons.push(deleteBtn);
      }
      return buttons
    },
    onOpenDelete(data) {
      const params = {
        DictItemIDs: [data.id]
      }
      this.$confirm('此操作将永久删除该属性, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteAttribute(params)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        await this.onInit()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    async onOpenEdit(data) {
      this.isEdit = true
      const editItem = {
        id: data.id,
        attributeName: data.attributeName,
        isUse: data.isUse,
        isReadonly: data.isReadonly,
        Multiple: data.Multiple,
        itemSource: await this.onValueList(data.id)
      }
      this.$refs.editView.onOpen(editItem)
    },
  },
  created() {
    this.onInit()
  },
  mounted() {
    this.initColumns()
  },
  watch: {}
}
</script>
