<template>
  <div class="board-column">
    <div class="board-column-header" v-if="headerText">
      {{ headerText }}
    </div>
    <draggable
      :list="list"
      v-bind="$attrs"
      class="board-column-content"
      v-if="list && list.length > 0"
      :set-data="setData"
    >
      <div v-for="element in list" :key="element.id" class="board-item">
        <div class="item" @click="$emit('click-edit', element)">
          {{ element[displayName] }}
        </div>
        <div class="board-item-del" v-if="showDel">
          <el-button type="danger" icon="el-icon-delete" circle @click="$emit('click-del', element)"></el-button>
        </div>
      </div>
    </draggable>
    <div class="board-column-add" v-if="showAdd">
      <el-button type="primary" icon="el-icon-edit" circle @click="$emit('click-edit')"></el-button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DragKanbanDemo',
  components: {
    draggable
  },
  props: {
    headerText: {
      type: String,
      default: ''
    },
    showAdd: {
      type: Boolean,
      default: false
    },
    showDel: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    displayName: {
      type: String,
      default: 'Value'
    },
    displayKey: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    'list'() {
      const sort = this.list.map(a => a[this.displayKey])
      this.$emit('on-sort', sort)
    }
  },
  methods: {
    setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/Sortable/issues/1012
      dataTransfer.setData('Text', '')
    }
  }
}
</script>
<style lang="scss" scoped>
.board-column {
  //min-width: 300px;
  min-height: 100px;
  height: auto;
  overflow: hidden;
  background: #f0f0f0;
  border-radius: 3px;

  .board-column-header {
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    padding: 0 20px;
    text-align: center;
    background: #333;
    color: #fff;
    border-radius: 3px 3px 0 0;
  }

  .board-column-content, .board-column-add {
    height: auto;
    overflow: hidden;
    border: 10px solid transparent;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    .board-item {
      cursor: pointer;
      width: 100%;
      height: 44px;
      margin: 5px;
      background-color: #fff;
      text-align: center;
      line-height: 34px;
      padding: 5px 10px;
      box-sizing: border-box;
      box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
      display: flex;
    }
    .item {
      flex: 1;
    }
    .board-item-del {
      width: 40px;
    }
  }
  .board-column-add .board-item {
    text-align: center;
  }
}
</style>

