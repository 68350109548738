import { param } from "@/utils"

// 属性列头配置
const attributeColumns = [
	{
		field: 'rowIndex',
		headerName: '序号',
		width: 70,
		cellClass: 't-c'
	},
	{
		field: 'attributeName',
		headerName: '属性名称',
		editable: false,
		cellClass: 't-c'
	},
	{
		field: 'attributeValueNum',
		headerName: '属性值个数',
		editable: false,
		cellClass: 't-c'
	},
  {
		field: 'isReadonly',
		headerName: '是否只读',
		editable: false,
		width: 80,
		cellClass: 't-c',
		valueFormatter(params) {
			const value = params.value === true
			return value ? '是' : '否'
		}
	},
	{
		field: 'isUse',
		headerName: '是否启用',
		editable: false,
		width: 80,
		cellClass: 't-c',
		valueFormatter(params) {
			const value = params.value
			return value ? '是' : '否'
		}
	},
	{
		field: 'Multiple',
		headerName: '是否多选',
		editable: false,
		cellClass: 't-c',
		valueFormatter(params) {
			const value = params.value
			return value ? '是' : '否'
		}
	},
	{
		field: 'createDate',
		headerName: '创建时间',
		editable: false,
		cellClass: 't-c'
	},
	{
		field: 'createMan',
		headerName: '创建人',
		editable: false,
		cellClass: 't-c'
	},
	{
		field: 'updateDate',
		headerName: '更新时间',
		editable: false,
		cellClass: 't-c'
	},
	{
		field: 'updateMan',
		headerName: '更新人',
		editable: false,
		cellClass: 't-c'
	},
	{
		field: 'operation',
		headerName: '操作',
		editable: false,
		width: 100,
		cellClass: 't-c',
		cellRendererFramework: 'GridCellButtonRender',
	}
]
const columns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
		valueFormatter: ({ node: { rowIndex } }) => {
			return rowIndex + 1;
		}
  },
  {
    field: 'ItemName',
    headerName: '名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'Count',
    headerName: '值个数',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'CreatedAt',
    headerName: '创建时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'CreateUserName',
    headerName: '创建人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'UpdatedAt',
    headerName: '更新时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'UpdateUserName',
    headerName: '更新人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'IsActive',
    headerName: '是否启用',
    editable: false,
    width: 100,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value === 2
      return value ? '是' : '否'
    }
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]

// 属性值猎头配置
const attributeValueColumns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c'
  },

  {
    field: 'attributeValueName',
    headerName: '值名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'attributeValue',
    headerName: '值',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'createDate',
    headerName: '创建时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'createName',
    headerName: '创建人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateDate',
    headerName: '更新时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateName',
    headerName: '更新人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'isUse',
    headerName: '是否启用',
    editable: false,
    width: 100,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value == 2
      return value ? '是' : '否'
    }
  }
]

// 编辑属性配置
const attributeEditColumns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
    editable: false
  },
  {
    field: 'attributeValueName',
    headerName: '值名称',
    editable: true,
    cellClass: 't-c'
  },
  {
    field: 'attributeValue',
    headerName: '值',
    editable: true,
    cellClass: 't-c'
  },
  {
    field: 'createDate',
    headerName: '创建时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'createName',
    headerName: '创建人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateDate',
    headerName: '更新时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateName',
    headerName: '更新人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'isUse',
    headerName: '是否启用',
    editable: false,
    width: 100,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      return value ? '是' : '否'
    }
  }

]

const defaultData = {
  RowIndex: 0,
  id: 0,
  attributeValueName: '',
  attributeValue: '',
  createDate: null,
  createName: null,
  updateDate: null,
  updateName: null,
  isUse: 1,
  isSelect: 1,
}

const isUseOptions = [
  {key: 1, value: '否'},
  {key: 2, value: '是'}
]

const isReadonlyOptions = [
  {key: false, value: '否'},
  {key: true, value: '是'}
]

const isSelectOptions = [
	{key: true, value: '是'},
  {key: false, value: '否'}
]
export {
	attributeColumns,
  columns,
  attributeValueColumns,
  attributeEditColumns,
  defaultData,
  isUseOptions,
  isReadonlyOptions,
  isSelectOptions
}
