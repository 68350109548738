<template>
  <KanBan :list="list" display-key="Id" display-name="Name" @on-sort="onSort" />
</template>

<script>
import KanBan from '@/components/Kanban'
import {GetSorts, SaveSort} from '@/api/config'
export default {
  components: {
    KanBan
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      list: []
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      const { code } = this;
      this.list = await GetSorts({ code })
    },
    async onSort(Ids) {
      const { code } = this;
      const param = { code, Ids }
      await SaveSort(param)
    }
  }
}
</script>

<style scoped>

</style>
